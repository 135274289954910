<template>
  <div id="app">
        <el-container> 
           
             <body>
              
               <div class="yshi">
                           <p style="font-size:40px">公司简介</p>
                           <p style="font-size:28px">邢台飞速电子科技有限公司成立于2016年,是一家从事智能硬件及互联网运营的专业型科技公司。</p>
                           <p style="font-size:28px">作为科技美学的引领者，通过人工智能辅助分析提高医美服务的专业性、有效性、安全性，低成本打造人工智能祛痘美容仪等一系列硬件产品，让每一位普通人都能轻松享受科技给生活带来的改变、实现自己独特的美。</p>
               </div>      
             
                
                   <div id="master" style="font-size:10px">
				             <p>&copy;2022 www.xt2fs.cn</p>
				             <p><a href="https://beian.miit.gov.cn/" style="color:whitesmoke">冀ICP备2020031397号-1</a></p>
				             <p>邮箱:duxiao#xt2fs.cn</p>
                  </div>

                  <div style="width:300px; padding:20px 5;">
			 		 		     <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13050202000370" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
								   <el-image :src="require('/euitest3/demo1/public/beian.png')" style="float:left;"/>
							     <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">冀公网安备 13050202000370号</p></a>
			 		 	      </div>

             </body>
              
          
        
     </el-container>

  </div>
</template>
<script>
  export default {
         name:"App",
         components:{

         }
  }
</script>
<style>
 #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: whitesmoke;
  height:100%;
 }
  .el-container {
    background-image:url('/euitest3/demo1/public/1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height:100%;
    width:100%;
  }
.yshi {
  width:600px;
  height:800px;
  text-align: justify;
  margin-top:100px;
  margin-left:1200px;
}
      
#master {
	position:absolute;
	left:70%;
	bottom:0;
	text-align :center;
 }			
                  
  body > .el-container {
    margin-bottom: 30px;

  }

</style>